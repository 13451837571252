import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./MainService.module.scss"

const MainService = ({ strapiData }) => {
  return (
    <section className={`${styles.subservice}`}>
      <Container>
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <Row>
          {strapiData?.cards &&
            strapiData?.cards?.map((e, i) => (
              <Col xl={3} md={6} key={i}>
                <div className={styles.servicesbox}>
                  <div className={styles.service}>
                    <div className={styles.flipbox}>
                      <div className={styles.flipboxinner}>
                        <div className={styles.flipboxfront}>
                          <img
                            decoding="async"
                            loading="lazy"
                            src={e?.image1[0]?.localFile?.publicURL}
                            alt={e?.image1[0]?.alternativeText}
                          />
                          <h3>{e?.title}</h3>
                        </div>
                        <div className={styles.flipboxback}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: e?.description?.description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  )
}

export default MainService
