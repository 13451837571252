// extracted by mini-css-extract-plugin
export var Group = "SubServices-module--Group--084bf";
export var Vector = "SubServices-module--Vector--cb928";
export var Vector3 = "SubServices-module--Vector3--63581";
export var boxContainer = "SubServices-module--boxContainer--8435c";
export var button = "SubServices-module--button--ec967";
export var cardText = "SubServices-module--cardText--47d9b";
export var circle = "SubServices-module--circle--97ce1";
export var h5 = "SubServices-module--h5--1cb1b";
export var hover = "SubServices-module--hover--d0dd8";
export var marginCard = "SubServices-module--marginCard--0452c";
export var solutionsflipbox = "SubServices-module--solutionsflipbox--3570f";
export var solutionsflipboxback = "SubServices-module--solutionsflipboxback--18383";
export var solutionsflipboxfront = "SubServices-module--solutionsflipboxfront--76626";
export var solutionsflipboxinner = "SubServices-module--solutionsflipboxinner--1adfd";
export var solutionsservice = "SubServices-module--solutionsservice--a8d0c";
export var startedBtn = "SubServices-module--startedBtn--c2a79";
export var subservice = "SubServices-module--subservice--37853";
export var subserviceCard = "SubServices-module--subserviceCard--11d9a";
export var subserviceHead = "SubServices-module--subserviceHead--93a34";
export var subserviceHeading = "SubServices-module--subserviceHeading--ed765";
export var subservicePara = "SubServices-module--subservicePara--7053c";