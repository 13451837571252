import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import take from "../../images/custom-softwere-development/take.png"
import Row from "react-bootstrap/Row"
import * as styles from "./CreateOwn.module.scss"

const CreateOwn = ({ strapiData }) => {
  return (
    <div className={`p-85 ${styles.head}`}>
      <img
        src={take}
        decoding="async"
        loading="lazy"
        alt="background"
        className={styles.take}
      />
      <Container>
        <Row className="gap-30">
          <Col lg={4} md={6} xs={12}>
            <div
              style={{ cursor: "default" }}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            ></div>
          </Col>
          {strapiData?.cards?.map((item, index) => {
            return (
              <Col
                lg={4}
                md={6}
                xs={12}
                key={index}
                style={{ display: "flex" }}
              >
                <div className={styles.box}>
                  <h3>{item?.title}</h3>{" "}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item?.description?.description,
                    }}
                  />
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default CreateOwn
