import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import build from "../../images/custom-softwere-development/build.png"
import Group from "../../images/custom-softwere-development/Group.png"
import build2 from "../../images/custom-softwere-development/build2.png"
import circle from "../../images/custom-softwere-development/circle.png"
import take from "../../images/custom-softwere-development/take.png"
import Row from "react-bootstrap/Row"
import * as styles from "./BuildInto.module.scss"

const BuildInto = ({ strapiData }) => {
  return (
    <div className="p-85">
      <img
        src={build}
        decoding="async"
        loading="lazy"
        alt="background"
        className={styles.build}
      />
      <img
        src={Group}
        decoding="async"
        loading="lazy"
        alt="background"
        className={styles.Group}
      />
      <Container>
        <div className={styles.heading}>
          <img
            src={build2}
            decoding="async"
            loading="lazy"
            alt="background"
            className={styles.build2}
          />
          <img
            src={circle}
            decoding="async"
            loading="lazy"
            alt="background"
            className={styles.circle}
          />
          <h2
            className="main-heading-36"
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
        </div>
        <Row className="justify-content-center">
          <Col xs={12} className="text-center">
            <img
              alt={strapiData?.secImages[0]?.alternativeText}
              src={strapiData?.secImages[0]?.localFile?.publicURL}
              width="100%"
              decoding="async"
              loading="lazy"
              height="auto"
            />
          </Col>
        </Row>
      </Container>
      <img
        src={take}
        decoding="async"
        loading="lazy"
        alt="background"
        className={styles.take}
      />
    </div>
  )
}

export default BuildInto
