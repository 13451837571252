import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Vector from "../../images/custom-softwere-development/Vector.png"
import Ellipse from "../../images/custom-softwere-development/Ellipse.png"
import Vector2 from "../../images/custom-softwere-development/Vector2.png"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner2 = ({ strapiData, breadCrumb }) => {
  return (
    <section className={styles.banner}>
      <Container>
        <nav className="mb-4">
          <Link to={`/`} className="text-dark">
            Home
          </Link>
          {" » "}
          {breadCrumb?.map((val, i) =>
            val?.url !== "#" ? (
              <React.Fragment key={i}>
                <Link to={`/${val?.url}/`} className="text-dark">
                  {val?.title}
                </Link>
                {" » "}
              </React.Fragment>
            ) : (
              <span className="text-dark">{val?.title}</span>
            )
          )}
        </nav>
        <Row className="align-items-start gap-30">
          <Col lg={6} md={12}>
            <h1
              className={styles.mainHeading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div
              className={`${styles.subHeading} pb-4 pt-2`}
              dangerouslySetInnerHTML={{
                __html: strapiData?.description?.description,
              }}
            />
            <ul className="pr-2 pr-md-0">
              {strapiData?.cards &&
                strapiData?.cards?.map(el => (
                  <Row className="pb-2" key={el?.title}>
                    <Col xs={1} className="pr-0">
                      <div className={styles.liBullet} />
                    </Col>
                    <Col xs={11} className="px-0">
                      <li>{el?.title}</li>
                    </Col>
                  </Row>
                ))}
            </ul>
            <div className="pt-4">
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className="btn_black_border"
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            className="text-center text-lg-right mt-5 mt-md-0"
          >
            <img
              src={strapiData?.secImages[0]?.localFile?.publicURL}
              alt={strapiData?.secImages[0]?.alternativeText}
              width="100%"
              decoding="async"
              loading="lazy"
              height="auto"
            />
          </Col>
        </Row>
      </Container>
      <img
        src={Vector}
        decoding="async"
        loading="lazy"
        alt="background"
        className={styles.vector}
      />
      <img
        src={Ellipse}
        decoding="async"
        loading="lazy"
        alt="background"
        className={styles.Ellipse}
      />
      <img
        src={Vector2}
        decoding="async"
        loading="lazy"
        alt="background"
        className={styles.Vector2}
      />
    </section>
  )
}

export default Banner2
