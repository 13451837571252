import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./SubIntro.module.scss"

const SubIntro = ({ strapiData }) => {
  return (
    <div className={styles.simplifyBg}>
      <div className={`${styles.subIntro}`}>
        <Container>
          <Row className="gap-30">
            <Col lg={6} md={12}>
              <div className={styles.Content}>
                <div dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
                <div
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.description?.description,
                  }}
                />
                {strapiData?.buttons[0] && (
                  <div className={styles.button}>
                    <Link
                      to={strapiData?.buttons[0]?.url}
                      className="btn_black_border"
                    >
                      {strapiData?.buttons[0]?.title}
                    </Link>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={6} md={12} style={{ display: "flex" }}>
              <img
                decoding="async"
                loading="lazy"
                alt={strapiData?.secImages[0]?.alternativeText}
                src={strapiData?.secImages[0]?.localFile?.publicURL}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default SubIntro
