// extracted by mini-css-extract-plugin
export var buttonHover = "MainService-module--buttonHover--8dcfc";
export var cardBody = "MainService-module--cardBody--dd5ae";
export var cardService = "MainService-module--cardService--9fd3c";
export var flipbox = "MainService-module--flipbox--c1760";
export var flipboxback = "MainService-module--flipboxback--e8c97";
export var flipboxfront = "MainService-module--flipboxfront--c3da0";
export var flipboxinner = "MainService-module--flipboxinner--e333e";
export var heading = "MainService-module--heading--0737e";
export var hover = "MainService-module--hover--8fd6f";
export var marginCard = "MainService-module--marginCard--4b34c";
export var service = "MainService-module--service--f828d";
export var servicesbox = "MainService-module--servicesbox--53d07";
export var subservice = "MainService-module--subservice--9e79d";
export var subserviceCard = "MainService-module--subserviceCard--3c92d";