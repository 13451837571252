import { graphql } from "gatsby"
import React from "react"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import WorkPortfolio from "../components/common/WorkPortfolio"
import Banner2 from "../components/custom-development-service/Banner2"
import BuildCustomize from "../components/custom-development-service/BuildCustomize"
import BuildInto from "../components/custom-development-service/BuildInto"
import Collaboration from "../components/custom-development-service/Collaboration"
import CreateOwn from "../components/custom-development-service/CreateOwn"
import MainService from "../components/custom-development-service/MainService"
import SubIntro from "../components/custom-development-service/SubIntro"
import SubServices from "../components/custom-development-service/SubServices"
import AngularTechStacks from "../components/application-development/AngularTechStacks"
import AngularTechStacksMobile from "../components/application-development/AngularTechStacksMobile"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/custom_software_development_1221bb3816.svg"
    />
  )
}
const customsoftwaredevelopment = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const banner = data?.strapiPage?.sections[0]
  const mainService = data?.strapiPage?.sections[1]
  const subIntroData = data?.strapiPage?.sections[2]
  const subserviceData = data?.strapiPage?.sections[3]
  const createOwn = data?.strapiPage?.sections[4]
  const buildInto = data?.strapiPage?.sections[5]
  const buildCustomize = data?.strapiPage?.sections[6]
  const collaboration = data?.strapiPage?.sections[7]
  const portfolio = data?.strapiPage?.sections[8]
  const faqs = data?.strapiPage?.sections[9]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner2 strapiData={banner} breadCrumb={data?.strapiPage?.buttons} />
      <MainService strapiData={mainService} />
      <SubIntro strapiData={subIntroData} />
      <SubServices strapiData={subserviceData} />
      <CreateOwn strapiData={createOwn} />
      <BuildInto strapiData={buildInto} />
      <BuildCustomize strapiData={buildCustomize} />
      <Collaboration
        strapiData={collaboration}
        heading="Custom Software Development Services"
      />
      {!isMobile && !isTablet ? (
        <AngularTechStacks heading={"Expertise In Proven Tech Stacks "} />
      ) : (
        <AngularTechStacksMobile heading={"Expertise In Proven Tech Stacks "} />
      )}
      <WorkPortfolio strapiData={portfolio} />
      <Faqs strapiData={faqs} />
    </MainLayout>
  )
}
export const query = graphql`
  query servicePage {
    strapiPage(slug: { eq: "software-development-services2" }) {
      sections {
        title
        cards {
          title
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            url
            title
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default customsoftwaredevelopment
