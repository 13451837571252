// extracted by mini-css-extract-plugin
export var build2 = "Collaboration-module--build2--5f484";
export var card = "Collaboration-module--card--13dea";
export var cardTitle = "Collaboration-module--cardTitle--0c922";
export var collaboration = "Collaboration-module--collaboration--8d5d6";
export var dedicatedBtn = "Collaboration-module--dedicatedBtn--08b39";
export var iconWrapper = "Collaboration-module--iconWrapper--71b6d";
export var leftArrow = "Collaboration-module--leftArrow--8e16c";
export var modalBody = "Collaboration-module--modalBody--66345";
export var modalContent = "Collaboration-module--modalContent--a1cfe";
export var modalHeader = "Collaboration-module--modalHeader--0a843";
export var optionBtn = "Collaboration-module--optionBtn--de1b0";
export var successPopUpThanksHeading = "Collaboration-module--successPopUpThanksHeading--b44c0";