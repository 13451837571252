import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Vector3 from "../../images/custom-softwere-development/Vector3.png"
import Vector from "../../images/custom-softwere-development/Vector.png"
import Group from "../../images/custom-softwere-development/Group.png"
import circle from "../../images/custom-softwere-development/circle.png"
import Row from "react-bootstrap/Row"
import * as styles from "./SubServices.module.scss"

const SubServices = ({ strapiData }) => {
  return (
    <section className={`${styles.subservice}`}>
      <img
        src={Vector}
        decoding="async"
        loading="lazy"
        alt="background"
        className={styles.Vector}
      />
      <img
        src={Group}
        decoding="async"
        loading="lazy"
        alt="background"
        className={styles.Group}
      />
      <Container>
        <div className={`p-1 p-md-3 ${styles.subserviceHead}`}>
          <div className={styles.subserviceHeading}>
            <div dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
            <div
              dangerouslySetInnerHTML={{
                __html: strapiData?.description?.description,
              }}
            />
          </div>
          <Row className="gap-30">
            <img
              src={Vector3}
              decoding="async"
              loading="lazy"
              alt="background"
              className={styles.Vector3}
            />
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i < 8 && (
                    <Col xl={4} md={6} key={i}>
                      <div>
                        <div className={styles.solutionsservice}>
                          <div className={styles.solutionsflipbox}>
                            <div className={` ${styles.solutionsflipboxinner}`}>
                              <div className={styles.solutionsflipboxfront}>
                                {e?.image1 && (
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    alt={e?.image1[0]?.alternativeText}
                                    src={e?.image1[0]?.localFile?.publicURL}
                                  />
                                )}
                                <div>
                                  <h3>{e?.title}</h3>
                                  {e?.buttons[0]?.title && (
                                    <Link
                                      className="btn_white_border mt-3"
                                      to={e?.buttons[0]?.url}
                                    >
                                      {e?.buttons[0]?.title}
                                    </Link>
                                  )}
                                </div>
                              </div>
                              <div className={styles.solutionsflipboxback}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: e?.description?.description,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
              )}
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i > 7 && (
                    <Col xl={4} md={6} key={i}>
                      <div className={styles.boxContainer}>
                        <h3>{e?.title}</h3>
                        {e?.buttons[0]?.title && (
                          <Link
                            className="btn_white_border mt-3"
                            to={e?.buttons[0]?.url}
                          >
                            {e?.buttons[0]?.title}
                          </Link>
                        )}
                      </div>
                    </Col>
                  )
              )}
            <img
              src={circle}
              decoding="async"
              loading="lazy"
              alt="background"
              className={styles.circle}
            />
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default SubServices
