import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Vector3 from "../../images/custom-softwere-development/Vector3.png"
import Row from "react-bootstrap/Row"
import * as styles from "./BuildCustomize.module.scss"

const BuildCustomize = ({ strapiData }) => {
  return (
    <div className={`${styles.build}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <Row className="align-items-center gap-30">
          {strapiData?.cards?.map((el, i) => (
            <Col xl={3} lg={4} md={6} xs={12} key={i}>
              <div className={styles.box}>
                <div className={styles.boxContent}>
                  <h3>{el?.title} </h3>
                  <img
                    decoding="async"
                    loading="lazy"
                    src={el?.image1[0]?.localFile?.publicURL}
                    alt={el?.image1[0]?.alternativeText}
                    width={54}
                    height={54}
                  />
                  <img
                    decoding="async"
                    loading="lazy"
                    className={styles.hoverShow}
                    alt={el?.image1[0]?.alternativeText}
                    src={el?.image2[0]?.localFile?.publicURL}
                    width={54}
                    height={54}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
        {strapiData?.buttons[0] && (
          <div className={styles.button}>
            <Link
              to={strapiData?.buttons[0]?.url}
              className="btn_black_border  btn btn-primary text-capitalize"
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          </div>
        )}
      </Container>
      <img
        src={Vector3}
        decoding="async"
        loading="lazy"
        alt="background"
        className={styles.Vector3}
      />
    </div>
  )
}

export default BuildCustomize
