import { Link } from "gatsby"
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import Spinner from "react-bootstrap/Spinner"
import leftArr from "../../images/svg/leftArr.svg"
import icoWebDev from "../../images/icons/web-dev.png"
import svgCompleted from "../../images/illustrations/completed.svg"
import build2 from "../../images/custom-softwere-development/build2.png"
import * as styles from "./Collaboration.module.scss"
const ServiceForm = React.lazy(() => import("../common/ServiceForm"))

const STEPS = [
  {
    label: "How many developers do you want to hire?",
    options: ["One Developer", "Small Team", "Large Team", "I'm not sure"],
  },
  {
    label: "What level of development experience do you need?",
    options: [
      "Junior",
      "Intermediate",
      "Lead Developer or Architect",
      "I'm not sure",
    ],
  },
  {
    label: "How long do you need the developers?",
    options: [
      "Less than one week",
      "1 to 4 week",
      "More than 4 weeks",
      "I'm not sure",
    ],
  },
  {
    label: "How urgently do you need to hire someone?",
    options: ["Within 48 hours", "1 week", "1 month", "Not sure"],
  },
  {
    label: "Do you have product specifications ready?",
    options: [
      "I have a rough idea of what I want to build",
      "I have a clear idea of what I want to build but don’t have a specification written",
      "I have clear specification written",
      "I'm not sure",
    ],
  },
  {
    label: "Please provide your contact details to get the proposal",
    options: [],
  },
]

const StepsContainer = props => {
  const { activeStep, handleNext, optionValues, prevLabel, successStatus } =
    props
  const { label, options } = STEPS[activeStep]
  const [formStatus, setFormStatus] = useState("init")
  const isSSR = typeof window === "undefined"
  const fallback = (
    <div className="text-center">
      <Spinner
        variant="light"
        size="sm"
        as="span"
        animation="border"
        role="status"
        aria-hidden="true"
      />
    </div>
  )

  React.useEffect(() => {
    return successStatus(formStatus === "success" ? true : false)
  }, [formStatus])

  return (
    <>
      {formStatus === "success" ? (
        <div className="mt-4">
          <img
            src={svgCompleted}
            alt="Submitted"
            width={300}
            decoding="async"
            loading="lazy"
          />
          <div className="h4 mt-4 mb-2">
            We have noted your requirements successfully!
          </div>
          <div className={`px-5 ${styles.successPopUpThanksHeading}`}>
            Thank you for taking time,
            <br /> we will get back to you shortly!
          </div>
        </div>
      ) : (
        <Container>
          <>
            <div className="h2 my-5">{label}</div>
            {activeStep === STEPS.length - 1 ? (
              !isSSR && (
                <React.Suspense fallback={fallback}>
                  <ServiceForm
                    status={formStatus}
                    setStatus={setFormStatus}
                    optionValues={optionValues}
                  />
                </React.Suspense>
              )
            ) : (
              <Row>
                {options.map((option, i) => (
                  <Col xs={12} sm={6} lg={4} key={i} style={{ padding: 15 }}>
                    <Button
                      variant={option === prevLabel ? "light" : "outline-light"}
                      className={styles.optionBtn}
                      onClick={() => handleNext(label, option)}
                    >
                      {option}
                    </Button>
                  </Col>
                ))}
              </Row>
            )}
          </>
        </Container>
      )}
    </>
  )
}

const FormModal = ({ heading, icon, name }) => {
  const [show, setShow] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleClose = () => {
    setActiveStep(0)
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const [activeStep, setActiveStep] = useState(0)
  const [optionValues, setOptionValues] = useState([])
  const [prevLabel, setPrevLabel] = useState("")

  const handleNext = (label, option) => {
    setPrevLabel("")
    setOptionValues([...optionValues, `${label} : ${option}`])
    setActiveStep(activeStep + 1)
  }

  const handleBack = e => {
    e.preventDefault()
    setPrevLabel(optionValues[optionValues.length - 1])
    setOptionValues(optionValues.slice(0, -1))
    setActiveStep(activeStep - 1)
  }

  return (
    <>
      <Col
        xs={12}
        md={6}
        xl={3}
        as="button"
        style={{ border: 0, backgroundColor: "transparent" }}
        onClick={handleShow}
      >
        <div className={styles.card}>
          <div className="mb-3">
            <img
              decoding="async"
              loading="lazy"
              src={icon}
              alt="project-based"
              width="128px"
            />
          </div>
          <h3 className={styles.cardTitle}>{name}</h3>
        </div>
      </Col>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
        contentClassName={styles.modalContent}
      >
        <Modal.Header className={styles.modalHeader} closeButton>
          {success ? (
            ""
          ) : activeStep === 0 ? (
            <Link to="#" className="backsvg" onClick={handleClose}>
              <img
                src={leftArr}
                className={styles.leftArrow}
                decoding="async"
                loading="lazy"
                color="#fff"
                size={20}
                alt="icon"
              />
              Go Home
            </Link>
          ) : (
            <a href="#!" onClick={handleBack} className="backsvg">
              <img
                src={leftArr}
                className={styles.leftArrow}
                decoding="async"
                loading="lazy"
                color="#fff"
                size={20}
                alt="icon"
              />
              Go Back
            </a>
          )}
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          {!success && (
            <div>
              <span className={styles.iconWrapper}>
                <img
                  decoding="async"
                  loading="lazy"
                  src={icoWebDev}
                  alt="web dev"
                  width={48}
                  height={48}
                />
              </span>
            </div>
          )}
          {!success && name !== "Project-Based" && (
            <div className="h4 mt-4">{heading}</div>
          )}
          <StepsContainer
            collabType={name}
            activeStep={activeStep}
            optionValues={[`service Type? : ${heading} `, ...optionValues]}
            prevLabel={prevLabel}
            handleNext={handleNext}
            successStatus={setSuccess}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

const Collaboration = props => {
  const { heading } = props

  return (
    <section className={styles.collaboration}>
      <img
        src={build2}
        decoding="async"
        loading="lazy"
        alt="background"
        className={styles.build2}
      />
      <Container>
        <div className="text-center mb-5">
          <h2
            className="h1 main-heading-36"
            dangerouslySetInnerHTML={{ __html: props?.strapiData?.title }}
          />
        </div>
        <Row>
          {props?.strapiData?.cards?.map((type, i) => (
            <FormModal
              key={i}
              icon={type.image1[0]?.localFile.publicURL}
              name={type?.title}
              heading={heading?.replace(/<[^>]*>/g, "")}
            />
          ))}
          <div className={styles.dedicatedBtn}>
            <Link
              to={props?.strapiData?.buttons[0]?.url}
              className="btn_white_border"
            >
              {props?.strapiData?.buttons[0]?.title}
            </Link>
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default Collaboration
